import { defineStore } from 'pinia'

interface MessageState {
    status: number,
    type: string,
    content: string
}

export const useMessageStore = defineStore('message', {
    state: (): MessageState => ({
        status: 0,
        type: "",
        content: "",
    }),
    actions: {
        createMessage(type: string, content: string){
            this.status = 1
            this.type = type
            this.content = content
        },
        removeMessage(){
            this.status = 0
            this.type = ""
            this.content = ""
        }
    }
})